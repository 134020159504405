import { createStore } from 'vuex'

export default createStore({
  state: {
    clientDesk: 0,
    favoriteCount: 0,
    basketCount: 0,
    searchString: "",
    isLoading: true,
    isAdminPage: false,
    isAuth: false,
    language: null,
    page: 'home',
    currentCategoryName: "",
    preventCategoryName: "",
    preventCategoryAliace: "",
    categoryWorkWith: "",
    categoryWorkTo: "",
    wifiName: "",
    wifiPassword: "",
    institutionWorkWith: "",
    institutionWorkTo: "",
  },
  mutations: {
    setInstitutionWorkWith (state, l) {
      state.institutionWorkWith = l;
    },
    setInstitutionWorkTo (state, l) {
      state.institutionWorkTo = l;
    },
    setWifiName (state, l) {
      state.wifiName = l;
    },
    setWifiPassword (state, l) {
      state.wifiPassword = l;
    },
    setClientDesk (state, l) {
      state.clientDesk = l;
    },
    setBasketCount (state, l) {
      state.basketCount = l;
    },
    setFavoriteCount (state, l) {
      state.favoriteCount = l;
    },
    setSearchString (state, l) {
      state.searchString = l;
    },
    setCategoryWorkTo (state, l) {
      state.categoryWorkTo = l;
    },
    setCategoryWorkWith (state, l) {
      state.categoryWorkWith = l;
    },
    setPreventCategoryAliace (state, l) {
      state.preventCategoryAliace = l;
    },
    setPreventCategoryName (state, l) {
      state.preventCategoryName = l;
    },
    setCurrentCategoryName (state, l) {
      state.currentCategoryName = l;
    },
    setIsLoading (state, l) {
      state.isLoading = l;
    },
    setLanguage (state, l) {
      state.language = l;
    },
    setCurrentPage (state, l) {
      state.page = l;
    }
  },
  actions: {
    setInstitutionWorkWith (context, l) {
      context.commit('setInstitutionWorkWith', l)
    },
    setInstitutionWorkTo (context, l) {
      context.commit('setInstitutionWorkTo', l)
    },
    setWifiName (context, l) {
      context.commit('setWifiName', l)
    },
    setWifiPassword (context, l) {
      context.commit('setWifiPassword', l)
    },
    setClientDesk (context, l) {
      context.commit('setClientDesk', l)
    },
    setBasketCount (context, l) {
      context.commit('setBasketCount', l)
    },
    setFavoriteCount (context, l) {
      context.commit('setFavoriteCount', l)
    },
    setSearchString (context, l) {
      context.commit('setSearchString', l)
    },
    setCategoryWorkTo (context, l) {
      context.commit('setCategoryWorkTo', l)
    },
    setCategoryWorkWith (context, l) {
      context.commit('setCategoryWorkWith', l)
    },
    setPreventCategoryAliace (context, l) {
      context.commit('setPreventCategoryAliace', l)
    },
    setPreventCategoryName (context, l) {
      context.commit('setPreventCategoryName', l)
    },
    setCurrentCategoryName (context, l) {
      context.commit('setCurrentCategoryName', l)
    },
    setIsLoading (context, l) {
      context.commit('setIsLoading', l)
    },
    setLanguage (context, l) {
      context.commit('setLanguage', l)
    },
    setCurrentPage (context, l) {
      context.commit('setCurrentPage', l)
    }
  },
  modules: {
  }
})
