export default {
    'en': {
        // ...
        SelectSection: 'Choose the section',
        Hello: 'Welcome to ',
        Shop: 'Institution',
        WorkWith: 'work from',
        WorkTo: 'to',
        HelloMorning: 'Good morning',
        HelloDay: 'Good day',
        HelloEvening: 'Good evening',
        HelloNight: 'Good night',
        SearchPlaceHolder: 'Search by menu items',
        NoItems: 'No sections',
        ErrorTitle: 'Sorry',
        ErrorDescription: 'The requested content was not found.',
        ToHome: 'Back to main',
        ToMenu: 'Back to menu',
        MyOrder: 'Favorites',
        OrderPageDescription_part1: 'On this page you can save your favorite items and be able to quickly add them to your order.',
        OrderPageDescription_part2: 'In order to add something to the selected one, click on the button in the lower right corner of the description of the menu item.',
        SearchPageTitle: 'Search by menu',
        InfoPageTitle: 'Our WiFi hotspots',
        InfoPageDescription_part1: 'This page was created so that you can easily get all the information you need to connect to one of our access points. In order to copy the point name or password, click on the required text or image -',
        InfoPageName: 'Name',
        InfoPageCopyName: 'Name copied',
        InfoPageCopyPassword: 'Password copied',
        SearchPageSubTitle: 'Search result',
        SearchNoItem: 'Unfortunately, no items were found for your request',
        AllCategories: 'All categories',
        ApiErrorText: 'An error occurred on the server. Sorry for the inconvenience!',
        SearchWarningText: 'Incorrect search data',
        AddToFavorite: 'Item added to favorites',
        DeleteFromFavorite: 'Item removed from favorites',
        CloseImage: 'To hide an image, click outside its area',
        NoCategory: 'No categories available',
        NoProducts: 'No positions available',
        Novelty: 'Novelty',
        CallHookah: 'call a hookah man',
        CallWaiter: 'call the waiter',
        CallCheck: 'cash bill',
        CallPos: 'pos bill',
        CallWaiting: 'Please wait, we are sending a request',
        CallOk: 'Request sent. Wait',
        CallBad: 'Warning! Requests can be sent no more than once per minute.',
        CallUnd: 'Of course',
        FeedbackPageTitle: 'Leave a review',
        FeedbackPageDescription_part1: 'On this page, you can leave a review and rate the level of service and dishes',
        FeedbackServiceTitle: 'Service impression',
        FeedbackDishTitle: 'Food impressions',
        FeedbackTextArea: 'Enter a review*',
        FeedbackButtonSend: 'Send',
        FeedbackRatingText0: 'Select a rating*',
        FeedbackRatingText1: 'Very bad',
        FeedbackRatingText2: 'Badly',
        FeedbackRatingText3: 'Average',
        FeedbackRatingText4: 'Perfectly',
        FeedbackRatingText5: 'Super',
        FeedbackSendWaiting: 'Please wait while we send your feedback',
        FeedbackSendOk: 'Your feedback has been sent successfully. Thank you',
        FeedbackSendBad: 'We could not send your feedback.',
        FeedbackSendBack: 'Go back',
        FeedbackRatingServiceValid: 'Rate the service',
        FeedbackRatingDishValid: 'Rate the dishes',
        FeedbackTextValidMin: 'Please enter a review',
        FeedbackTextValidMax: 'Feedback must contain a maximum of 500 characters',
        MyOrderPage: 'My order',
        AddToBasket: 'Item added to basket',
        DeleteFromBasket: 'Item removed from basket',
        OrderPageViewDescription_part1: 'On this page, you can form your order and send it to the waiters for faster execution.',
        OrderPageViewDescription_part2: 'To add something to the order, click on the button in the lower right corner of the menu item description.',
        OrderPageViewSumTitle: 'Order price',
        OrderPageViewButtonSubmitText: 'Order',
        OrderPageViewButtonClearText: 'Clear',
        OrderPageViewClearBasketText: 'Are you sure you want to empty the basket?',
        OrderPageViewClearBasketCancel: 'Cancel',
        OrderPageViewClearBasketOk: 'Basket cleared',
        OrderPageViewOrderSendWaiting: 'Please wait... Your order is being sent',
        OrderPageViewOrderSendOk: 'Your order has been accepted. We will fulfill it as soon as possible',
        OrderPageViewOrderSendBad: 'We were unable to accept your order. Try again or call the waiter.',
        OrderPageViewCommentTitle: 'Comment on the item',
        Allergens: 'Allergens',
        InText: "I'm inside",
        OutText: 'Delivery',
        OrderText: 'Order',
        AddressText: 'Address',
        PayText: 'Payment',
        OurGeoLocation: 'Our geolocation',
        YourDesk: 'Your desk',
        NoItemCount: 'Not available',
        IsFirstText: 'Give first',
        ToNext: 'Next',
        OrderAddressPageViewTitle: 'Address',
        OrderFormName: "Your name*",
        OrderFormPhone: 'Your phone number*',
        OrderFormAddress: 'Delivery address*',
        OrderFormDelivery: 'Delivery method*',
        OrderFormPayMethod: 'Payment method*',
        OrderFormComment: 'Order comment',
        OrderFormEmailText: 'If you want to receive our news or special offers, leave us your email',
        OrderFormEmail: 'Your email',
        OrderFormTotalSum: 'The sum of your order is',
        OrderFormTotalSumDescription: "We will contact you after successful payment",
        OrderFormTotalAccept: 'Pay',
        OrderFormTotalCancel: 'Cancel',
        UndefinedTable: 'To place an order, scan the QR code on your table in the restaurant',
    },
    'ua': {
        // ...
        SelectSection: 'Оберіть розділ',
        Hello: 'Вітаємо Вас в ',
        Shop: 'Заклад',
        WorkWith: 'працює з',
        WorkTo: 'по',
        HelloMorning: 'Доброго ранку',
        HelloDay: 'Доброго дня',
        HelloEvening: 'Доброго вечора',
        HelloNight: 'Доброї ночі',
        SearchPlaceHolder: 'Пошук по позиціям в меню',
        NoItems: 'Розділи відстуні',
        ErrorTitle: 'Вибачте',
        ErrorDescription: 'Запитаний вміст не знайдено.',
        ToHome: 'На головну',
        ToMenu: 'Меню',
        MyOrder: 'Обране',
        OrderPageDescription_part1: 'На цій сторінці ви можете зберегти ваші улюблені позиції і мати можливість швидко додати їх до вашого замовлення.',
        OrderPageDescription_part2: 'Для того щоб щось додати до обраного натисніть на кнопку в правому нижньому кутку опису позиції меню.',
        InfoPageTitle: 'Наші точки доступу WiFi',
        InfoPageDescription_part1: 'Ця сторінка створена для того, щоб Ви могли легко отримати всю необхідну Вам інформацію для підключення до однієї з наших точок доступу. Для того щоб скопіювати назву точки або пароль натисніть на необхідний текст або зображення - ',
        InfoPageName: 'Назва',
        InfoPageCopyName: 'Назву скопійовано',
        InfoPageCopyPassword: 'Пароль скопійовано',
        SearchPageTitle: 'Пошук по меню',
        SearchPageSubTitle: 'Результат пошуку',
        SearchNoItem: 'На жаль по Вашому запиту не знайдено позицій',
        AllCategories: 'Усі категорії',
        ApiErrorText: 'На сервері виникла помилка. Вибачте за незручності!',
        SearchWarningText: 'Некоректні дані для пошуку',
        AddToFavorite: 'Позицію додано до обраного',
        DeleteFromFavorite: 'Позицію видалено з обраного',
        CloseImage: 'Для того щоб сховати зображення натисніть поза його зоною',
        NoCategory: 'Категорії відстуні',
        NoProducts: 'Позиції відстуні',
        Novelty: 'Новинка',
        CallHookah: 'покликати кальянника',
        CallWaiter: 'покликати офіціанта',
        CallCheck: 'рахунок готівкою',
        CallPos: 'рахунок терміналом',
        CallWaiting: 'Зачекайте, надсилаємо запит',
        CallOk: 'Запит надіслано. Очікуйте',
        CallBad: 'Увага! Надсилати запити можна не частіше ніж один раз на хвилину.',
        CallUnd: 'Зрозуміло',
        FeedbackPageTitle: 'Залишити відгук',
        FeedbackPageDescription_part1: 'На цій сторінці ви можете залишити відгук та оцінити рівень обслуговування і страв',
        FeedbackServiceTitle: 'Враження від обслуговування',
        FeedbackDishTitle: 'Враження від страв',
        FeedbackTextArea: 'Введіть відгук*',
        FeedbackButtonSend: 'Надіслати',
        FeedbackRatingText0: 'Оберіть оцінку*',
        FeedbackRatingText1: 'Дуже погано',
        FeedbackRatingText2: 'Погано',
        FeedbackRatingText3: 'Середньо',
        FeedbackRatingText4: 'Чудово',
        FeedbackRatingText5: 'Супер',
        FeedbackSendWaiting: 'Зачекайте, надсилаємо ваш відгук',
        FeedbackSendOk: 'Ваш відгук успішно надіслано. Дякуємо',
        FeedbackSendBad: 'Нам не вдалося надіслати ваш відгук.',
        FeedbackSendBack: 'Назад',
        FeedbackRatingServiceValid: 'Дайте оцінку обслуговуванню',
        FeedbackRatingDishValid: 'Дайте оцінку стравам',
        FeedbackTextValidMin: 'Введіть будь ласка відгук',
        FeedbackTextValidMax: 'Відгук повинен містити максимум 500 символів',
        MyOrderPage: 'Моє замовлення',
        AddToBasket: 'Позицію додано до кошика',
        DeleteFromBasket: 'Позицію видалено з кошику',
        OrderPageViewDescription_part1: 'На цій сторінці ви можете сформувати ваше замовлення і надіслати його офіціантам для скорішого його виконання.',
        OrderPageViewDescription_part2: 'Для того щоб щось додати до замовлення натисніть на кнопку в правому нижньому кутку опису позиції меню.',
        OrderPageViewSumTitle: 'Замовлення на суму',
        OrderPageViewButtonSubmitText: 'Замовити',
        OrderPageViewButtonClearText: 'Очистити кошик',
        OrderPageViewClearBasketText: 'Ви впевненні що хочете очистити кошик?',
        OrderPageViewClearBasketCancel: 'Скасувати',
        OrderPageViewClearBasketOk: 'Кошик очищено',
        OrderPageViewOrderSendWaiting: 'Зачекайте... Замовлення надсилається',
        OrderPageViewOrderSendOk: 'Ваше замовлення прийнято. Ми виконаємо його якнайшвидше',
        OrderPageViewOrderSendBad: 'Нам не вдалося прийняти ваше замовлення. Спробуйте ще раз або покличте офіціанта.',
        OrderPageViewCommentTitle: 'Коментар до позиції',
        Allergens: 'Алергени',
        InText: 'Я в закладі',
        OutText: 'Доставка',
        OrderText: 'Замовлення',
        AddressText: 'Адреса',
        PayText: 'Оплата',
        OurGeoLocation: 'Наша геолокація',
        YourDesk: 'Ваш столик',
        NoItemCount: 'Немає в наявності',
        IsFirstText: 'Подати першим',
        ToNext: 'Далі',
        OrderAddressPageViewTitle: 'Адреса',
        OrderFormName: "Ваше ім'я*",
        OrderFormPhone: 'Ваш номер телефон*',
        OrderFormAddress: 'Адреса доставки*',
        OrderFormDelivery: 'Спосіб доставки*',
        OrderFormPayMethod: 'Спосіб оплати*',
        OrderFormComment: 'Коментар до замовлення',
        OrderFormEmailText: 'Якщо бажаєте отримувати наші новини або спеціальні пропозиції то залиште нам свій email',
        OrderFormEmail: 'Ваш email',
        OrderFormTotalSum: 'Сума вашого замовлення складає',
        OrderFormTotalSumDescription: "Після успішної оплати ми зв'яжемося з вами",
        OrderFormTotalAccept: 'Сплатити',
        OrderFormTotalCancel: 'Скасувати',
        UndefinedTable: 'Для того щоб зробити замовлення відскануйте QR код на вашому столику в закладі',
    },
}