import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'
import i18n from './i18n'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import VueCookies from 'vue3-cookies'
import 'vue3-easy-data-table/dist/style.css';
// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import lazyPlugin from 'vue3-lazy'

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)
i18n.setup()
app.config.globalProperties.$api_link = "https://api.offshore-cafe.com.ua"
app.component('EasyDataTable', Vue3EasyDataTable)
app.use(store).use(i18n.vueI18n).use(router).use(VueAxios, axios).use(Notifications).use(VueViewer)
.use(vuetify)
.use(VueCookies, {
  expireTimes: "1d",
  path: "/",
  domain: "",
  secure: false,
  sameSite: "None"
})
.use(lazyPlugin, {
  loading: 'assets/images/loading.gif',
  error: 'error.png'
})
.mount('#app')
