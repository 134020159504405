<script>
import PreloaderOff from "./components/PreloaderOff.vue";
import PagesHeader from "./components/PagesHeader.vue";
import TimeComponent from "./components/TimeComponent.vue";
import SearchComponent from "./components/SearchComponent.vue";
import MenubarComponent from "./components/MenubarComponent.vue";

export default {
  components: {
    PreloaderOff,
    PagesHeader,
    TimeComponent,
    SearchComponent,
    MenubarComponent
  },
  data() {
    return {
      isLoading: true,
      Langs: [
        {
          name: "ua",
          icon: "assets/images/ua.png",
        },
        {
          name: "en",
          icon: "assets/images/uk.png",
        },
      ],
      Social: {
        tg: "",
        fb: "",
        tk: "",
        inst: "",
        phone: "",
      },
      OwnerName: "",
      Logo: '',
      Color: '',
      ColorRGB: '',
      AlertButton: {
        isActive: false,
        Waiter: false,
        Hookah: false,
        Pos: false,
        Bill: false
      },
      ShowSupTitle: true,
      WeWorkFrom: "09:00",
      WeWorkTo: "21:00",
    };
  },
  methods: {
    SetIndividualStyle() {
      const globalStyles = `
        .menubar-area.style-4 .toolbar-inner .nav-link.active svg path, .menubar-area.style-4 .toolbar-inner .menu-toggler.active svg path,
        .order-button-basket .basket-active path {
          fill: ${this.Color} !important;
        }
        .menubar-area.style-4 .toolbar-inner .nav-link.add-post
        , .menubar-area.style-4 .toolbar-inner .menu-toggler.add-post, ::selection, .order-count {
          background: ${this.Color} !important;
        }
        .menubar-area.style-4 .toolbar-inner .nav-link.add-post, .menubar-area.style-4 .toolbar-inner .menu-toggler.add-post {
          box-shadow: 0 4px 20px -8px ${this.Color} !important;
        }
        .all-alert-button-wrapper .alert-button-close {
          color: ${this.Color} !important;
        }
        .alert-button, .alert-button-fake {
          border-color: ${this.Color} !important;
        }
        .notify-cart {
          background-color: rgb(${this.ColorRGB}, 0.15) !important;
        }
        .notify-cart .badge {
          background-color: rgb(${this.ColorRGB}, 0.6) !important;
        }
        .order-button .fa-solid.fa-heart {
          color: ${this.Color} !important;
        }
        .order-button .fa-regular.fa-heart {
          color: #7d8fab !important;
        }
        .search-box .input-group:focus-within {
          border-color: ${this.Color} !important; 
        }
        .search-box .input-group:focus-within .input-group-text svg path {
          fill: ${this.Color} !important; 
        }
        .viewer-button {
          background-color: ${this.Color} !important; 
        }
        .btn-primary {
          background-color: ${this.Color} !important; 
          border-color: ${this.Color} !important; 
        }
        .card-media img, .text-success {
          color: ${this.Color} !important; 
        }
        .form-control:focus, .form-control:active, .form-control.active {
          border-color: ${this.Color} !important; 
        }
        .input-group:focus-within .input-group-text,
        .input-group:focus-within .form-control {
          border-color: ${this.Color} !important; 
        }
        :root {
          --rgba-primary-3: rgba(${this.ColorRGB}, 0.3) !important;
          --rgba-primary-6: rgba(${this.ColorRGB}, 0.6) !important;
          --rgba-primary-9: rgba(${this.ColorRGB}, 0.9) !important;
        }
      `;
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(globalStyles));
      document.head.appendChild(style);
    }
  },
  computed: {
    Title() {
      return this.$t("Hello") + this.OwnerName + " 👋";
    },
    SectionName() {
      return this.$t("Shop");
    },
    SearchPlaceHolder() {
      return this.$t("SearchPlaceHolder");
    },
  },
  mounted() {
    this.$store.dispatch("setPreventCategoryName", this.SectionName);
    const promise1 = this.axios.get(
      this.$api_link + "/api/public/settings"
    );
    Promise.all([promise1])
      .then(
        function (values) {
          var setings_ = values[0].data;
          if (setings_) {
            this.Social.fb = setings_.facebook;
            this.Social.inst = setings_.instagram;
            this.Social.tk = setings_.tiktok;
            this.Social.phone = setings_.phone;
            this.OwnerName = setings_.company_name;
            this.Color = setings_.main_color;
            this.ColorRGB = setings_.rgb;
            this.Logo = setings_.image;
            this.AlertButton.isActive = setings_.show_call_button;
            this.AlertButton.Waiter = setings_.show_waiter_button;
            this.AlertButton.Hookah = setings_.show_hookah_button;
            this.AlertButton.Bill = setings_.show_bill_button;
            this.AlertButton.Pos = setings_.show_pos_button;
            this.$store.dispatch("setWifiName", setings_.wifi_name);
            this.$store.dispatch("setWifiPassword", setings_.wifi_password);
            this.$store.dispatch("setInstitutionWorkWith", setings_.work_from);
            this.$store.dispatch("setInstitutionWorkTo", setings_.work_to);
            this.$store.dispatch("setCategoryWorkWith", setings_.work_from);
            this.$store.dispatch("setCategoryWorkTo", setings_.work_to);
            document.title = this.OwnerName + " | цифрове меню"
            this.SetIndividualStyle();
          }
          this.$store.dispatch("setIsLoading", false);
        }.bind(this)
      )
      .catch(
        function (err) {
          this.$notify({
            title: this.$t("ApiErrorText"),
            type: "error",
            duration: 5000,
          });
          this.$store.dispatch("setIsLoading", false);
        }.bind(this)
      );
  },
};
</script>

<template>
  <div class="page-wraper">
    <notifications position="top center" />
    <PreloaderOff :isLoading="$store.state.isLoading" />
    <PagesHeader :OwnerName="OwnerName" :Logo="Logo" :Langs="Langs" :Social="Social" />
    <TimeComponent v-if="$store.state.page != 'ErrorPage' && $store.state.page != 'Admin'" :Page="$store.state.page"
      :SectionName="$store.state.currentCategoryName" :WorkSectionName="$store.state.preventCategoryName"
      :ShowSupTitle="ShowSupTitle" :title="Title" :WeWorkFrom="$store.state.categoryWorkWith"
      :WeWorkTo="$store.state.categoryWorkTo" />
    <!-- Page Content -->
    <div class="page-content">
      <div class="content-inner pt-0">
        <div class="container p-b30">
          <SearchComponent v-if="$store.state.page != 'ErrorPage' && $store.state.page != 'OrderPageView'"
            :placeHolder="SearchPlaceHolder" />
          <!-- Dashboard Area -->
          <router-view :key="$route.fullPath" v-slot="{ Component }">
            <transition name="route" mode="out-in" appear>
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
      <MenubarComponent :AlertButtonSettings="AlertButton" />
    </div>
  </div>
</template>

<style lang="scss">
.route-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>
