import { createRouter, createWebHashHistory } from 'vue-router'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/menu/:aliace_sec',
    name: '',
    children: [
      {
        path: '',
        name: 'categories',
        component: () => import('../views/CategoriesView.vue'),
      },
      {
        path: 'category/:aliace_cat',
        name: 'categories.products',
        component: () => import('../views/ProductsView.vue')
      }
    ]
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import('../views/FavoritesView.vue')
  },
  {
    path: '/order',
    name: '',
    children: [
      {
        path: '',
        name: 'order',
        component: () => import('../views/OrderView.vue'),
      },
      {
        path: 'address',
        name: 'order.address',
        component: () => import('../views/OrderAddressView.vue'),
      },
      {
        path: 'pay',
        name: 'order.pay',
        component: () => import('../views/OrderPayView.vue')
      }
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/InfoView.vue')
  },
  { path: '/:pathMatch(.*)*', component: () => import('../views/ErrorPage.vue') }
]

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes
})

router.beforeEach(async (to, from) => {
  const url = new URL(location.href);
  const searchParams = url.searchParams;
  var client_desk_ = searchParams.get('d');
  if(client_desk_ != null) {
    client_desk_ = client_desk_.substring(0, client_desk_.length-1);
  }
  store.dispatch("setClientDesk", client_desk_);
  var favorites = localStorage.getItem("favorites");
  if (favorites) {
    favorites = JSON.parse(favorites);
    if (favorites.length || favorites.length > 0) {
      store.dispatch("setFavoriteCount", favorites.length);
    }
  } else {
    localStorage.setItem("favorites",JSON.stringify([]));
    store.dispatch("setFavoriteCount", 0);
  }
  var basket = localStorage.getItem("basket");
  if (basket) {
    basket = JSON.parse(basket);
    if (basket.length || basket.length > 0) {
      store.dispatch("setBasketCount", basket.length);
    }
  } else {
    localStorage.setItem("basket",JSON.stringify([]));
    store.dispatch("setBasketCount", 0);
  }
})


export default router
